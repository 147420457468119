interface chartControl {
    whereClause: String,
    definitionParameters: {
        OrgUnit_ID: number | null,
        WBS_ID: number | null,
        Import_ID: number | null,
        FromPreviousCutOff: boolean,
        ShowAllPeriods: boolean
    },
    createOrRefresh: Function,
}

// use this when you have one chart in app --- this is deprecated
//usage:
//chartControl.createOrRefresh();
export const chartControl: chartControl = { 
    whereClause: '',
    definitionParameters: {
        OrgUnit_ID: null,
        WBS_ID: null,
        Import_ID: null,
        FromPreviousCutOff: false,
        ShowAllPeriods: false
    },
    createOrRefresh: () => {}, // overridden from CostCurvesLibs
}


//usage:
//chartsControl['chartContainerId'].createOrRefresh();
// You have to provide same ID string you passed to chartContainerId prop to CostCurves component
// This object will contain all parameters and refresh functions of charts divided by chartContainerId key
export const chartsControl = { 
    //chartContainerId = {
    //     whereClause: String,
    //     definitionParameters: {
    //         OrgUnit_ID: number | null,
    //         WBS_ID: number | null,
    //         Import_ID: number | null,
    //         FromPreviousCutOff: boolean,
    //         ShowAllPeriods: boolean
    //     },
    //     createOrRefresh: Function,
    //}
    //chartContainerId2 = ...
}